import React from "react"
import {Fade} from 'react-reveal'
const Hero = () => { 
    const orderNow = () => { 
      window.scrollTo({ 
        top:1150, 
        behavior:'smooth'
      })
    }
  
  
  return <section class="hero section" id="home">
      <Fade top> 
      <h1 className="hero__title">
      <span className = "hero__title--green">Star</span>
      <span className = "hero__title--grey">bucks</span>
      
      </h1>

      <p> Opulent coffee beans, brimming with a wealth  of flavor  </p>
      
    
    <button className="hero__button" onClick ={orderNow}>Order Now</button>

      </Fade>

  </section>

}
export default Hero;