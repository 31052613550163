import React from "react"
const Blog = () => { 
    return <section class="blog section" id="blog">
    <div class="blog__container container">
      <h2 class="section__title">
        Our blogs coffee with <br />
        insightful topic
      </h2>

      <div class="blog__content grid">
        <article class="blog__card">
          <div class="blog__image">
            <img src="./media/beans.jpeg" alt="" class="blog__img" />
            <a href="#" class="blog__button">
              <i class="bx bx-right-arrow-alt"></i>
            </a>
            <div class="blog__stats">
              <div class="blog__reaction">
                <i class="bx bx-comment"></i>
                <span>12</span>
              </div>
              <div class="blog__reaction">
                <i class="bx bx-show"></i>
                <span>76,5k</span>
              </div>
            </div>
          </div>

          <div class="blog__data">
            <h2 class="blog__title">Where Starbucks Coffee is harvested</h2>
            <p class="blog__description">
              Find out where the rich Starbucks Coffee are harvested from, and where 
              the journey of your coffee begins. 
            </p>
          </div>
        </article>
        <article class="blog__card">
          <div class="blog__image">
            <img src="./media/barist.jpeg" alt="" class="blog__img" />
            <a href="#" class="blog__button">
              <i class="bx bx-right-arrow-alt"></i>
            </a>
            <div class="blog__stats">
              <div class="blog__reaction">
                <i class="bx bx-comment"></i>
                <span>96</span>
              </div>
              <div class="blog__reaction">
                <i class="bx bx-show"></i>
                <span>356,7k</span>
              </div>
            </div>
          </div>

          <div class="blog__data">
            <h2 class="blog__title">Life at Starbucks</h2>
            <p class="blog__description">
              Find out what it's like to have a career at Starbucks, 
              from the barista, to the cashier!
            </p>
          </div>
        </article>
      </div>
    </div>
  </section>
}
export default Blog; 
