import Header from "./component/Header";
import Hero from "./component/Hero";
import Special from "./component/Special";
import Products from "./component/Products";
import Place from "./component/Place";
import Blog from "./component/Blog";
import Footer from "./component/Footer";
import React from "react"

function App() {
  return (
    <> 
     <Header/> 
     <main className = "main"> 
     <Hero/>
     <Special/>
     <Products/>
     <Place /> 
     <Blog /> 

     </main>
     <Footer/> 
    </>
  );
}

export default App;
